@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

body {
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.fade-route {
  position: absolute;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  transition: transform 0.5s ease-in-out;
}

.route-enter {
  transform: translateX(100%);
  background-color: red; /* Add a color for debugging */
}

.route-enter-active {
  transform: translateX(0%);
  background-color: green; /* Add a color for debugging */
}

.route-exit {
  transform: translateX(0%);
  background-color: blue; /* Add a color for debugging */
}

.route-exit-active {
  transform: translateX(-100%);
  background-color: yellow; /* Add a color for debugging */
}
