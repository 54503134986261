@import url('https://fonts.cdnfonts.com/css/orbiter-fbs-trial');
@import './index.css';


.fade-route {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}


.route-enter {
  transform: translateX(100%);
}

.route-enter-active {
  transform: translateX(0%);
}

.route-exit {
  transform: translateX(0%);
}

.route-exit-active {
  transform: translateX(-100%);
}

.route-link {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.route-link:hover {
  color: #333;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  outline: none !important;
  border: none !important;

}

.css-yf8vq0-MuiSelect-nativeInput {
  position: relative !important;
}


/* NOTIFICATION */

@media (max-width: 300px) {
  .toast-message-container {
    width: 200px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 500px) {
  .toast-message-container {
    width: 300px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}


@media (min-width: 500px) {
  .toast-message-container {
    width: 600px !important;
  }
}

.toast-message-container .Toastify__toast {
  margin-bottom: 0px;
  padding: 11px;
  border-radius: 30px;
  align-items: center;
  box-shadow: none;
}

.Toastify__toast-container.toast-message-container {
  z-index: 100000 !important;
}

.Toastify__toast-theme--light {
  background-color: #FFF;
}

/* ERROR */
.Toastify__toast.toast-error {
  border: 1px solid #ff0101;
  background: #f3f6ff !important;
  color: #ff0101;
}

.Toastify__toast.toast-error::before {
  content: url("/src/assets/images/close-circle.svg");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
  margin-right: 8px
}

.Toastify__toast.toast-success {
  border: 1px solid #0EC17E;
  background: #FFF !important;
  color: #0b8800;
}

.Toastify__toast.toast-success::before {
  content: url("/src/assets/images/tick-circle.svg");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
  margin-right: 8px
}

/* SUCCESS */
.Toastify__toast.toast-message-container--success {
  border: 1px solid #0EC17E;
  background: #FFF !important;
}

.Toastify__toast.toast-message-container--success .toast-success {
  content: url("/src/assets/images/tick-circle.svg") !important;
}

.Toastify__toast.toast-message-container--success {
  content: url("/src/assets/images/tick-circle.svg") !important;
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast.toast-message-container--success .Toastify__toast-body {
  color: #0b8800;
  margin-right: 22px;
}

/* WARNING */
.Toastify__toast.toast-message-container--warning {
  border: 1px solid #f1daac;
  background: #f3f6ff !important;
}

.Toastify__toast.toast-message-container--warning::after {
  content: url("/src/assets/images/tick-circle.svg");
  position: absolute;
  left: 265px;
}

.Toastify__toast.toast-message-container--warning::before {
  content: url("/src/assets/images/tick-circle.svg");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast.toast-message-container--warning .Toastify__toast-body {
  color: #efac01;
  margin-right: 22px;
}

/* INFORMATION */
.Toastify__toast.toast-message-container--info {
  background: #f3f6ff !important;
  border: 1px solid #accdf1;
}

.Toastify__toast.toast-message-container--info::after {
  content: url("/src/assets/images/tick-circle.svg");
  position: absolute;
  left: 265px;
}

.Toastify__toast.toast-message-container--info::before {
  content: url("/src/assets/images/tick-circle.svg");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast.toast-message-container--info .Toastify__toast-body {
  color: #0068db;
  margin-right: 22px;
}

.Toastify__toast-body {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  margin-left: 20px !important;
}

.Toastify__toast>button>svg {
  display: none;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.ant-modal-confirm-title {
  text-align: center;
  font-size: 24px !important;
  font-weight: bold !important;
}

.ant-modal-confirm-content {
  text-align: center;
  font-size: medium;
  font-weight: 700;
}

.anticon .anticon-exclamation-circle {
  display: none !important;
}

.ant-modal{
  margin-top: 100px !important;
}

.ant-modal-confirm-btns{
  width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.ant-btn-primary {
  width: 100px !important;
  border: 1px solid #0068db;
  background-color: #0979A1;
}

 .ant-btn-default{
  width: 100px !important;
  border: 1px solid #0068db;
  color: #0068db;
 }



 @media (max-width: 640px) {
  .table-container {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table {
    width: auto;
    min-width: 300px; /* Set the minimum width as per your design */
  }

  .table thead,
  .table tbody,
  .table tr,
  .table th,
  .table td {
    display: block;
  }

  .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5; /* Add bottom border between rows */
    margin-bottom: 10px; /* Add margin between rows */
  }

  .table td {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: none;
  }
}


@media (min-width: 768px) {
  .container {
    margin-top: -40px;
  }
}

/* @media (max-width: 768px) {
  .container {
    margin-top: 120px;
  }
} */

@media (min-width: 820px) {
  .container {
    margin-top: 1px;
  }
}

/* @media (min-width: 853px) {
  .container {
    margin-top: 225px;
  }
} */

/* @media (min-width: 1024px) {
  .container {
    margin-top: 40px;
  }
} */

/* @media (min-width: 1024px) {
  .container {
    margin-top: 350px;
  }
} */

/* 
@media (min-width: 1080px) {
  .container {
    margin-top: 36px;
  }
} */

/* @media (min-width: 1280px) {
  .container {
    margin-top: 80px;
  }
} */

.ant-modal-close-x {
  color: #000 !important; /* Set the color of the close icon to black */
  font-size: 20px !important; /* Adjust the size if needed */
}

.no-scroll {
  overflow: hidden;
}


